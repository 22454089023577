<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <desc>Close.</desc>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect id="Rectangle" fill-opacity="0.9" fill="#1A1A1A" x="0" y="0" width="32" height="32" rx="16"></rect>
      <path
        d="M21.3630324,9.27934912 L15.2579608,15.3708488 C14.9140131,15.7135264 14.9140131,16.285594 15.2579608,16.629679 L21.3630324,22.7197713 C21.7374479,23.0934096 22.3285234,23.0934096 22.7029389,22.7197713 C23.0990204,22.3243199 23.0990204,21.6657033 22.7029389,21.2695483 L17.5897637,16.1680844 C17.4970061,16.0759063 17.4970061,15.9232142 17.5897637,15.831036 L22.7029389,10.7295722 C23.0990204,10.3341208 23.0990204,9.67550418 22.7029389,9.27934912 C22.5153926,9.09358547 22.2743584,9 22.0333242,9 C21.7916129,9 21.5505787,9.09358547 21.3630324,9.27934912 Z"
        id="Back" fill="#FFFFFF"></path>
      <path
        d="M15.3630324,9.27934912 L9.25796077,15.3708488 C8.91401308,15.7135264 8.91401308,16.285594 9.25796077,16.629679 L15.3630324,22.7197713 C15.7374479,23.0934096 16.3285234,23.0934096 16.7029389,22.7197713 C17.0990204,22.3243199 17.0990204,21.6657033 16.7029389,21.2695483 L11.5897637,16.1680844 C11.4970061,16.0759063 11.4970061,15.9232142 11.5897637,15.831036 L16.7029389,10.7295722 C17.0990204,10.3341208 17.0990204,9.67550418 16.7029389,9.27934912 C16.5153926,9.09358547 16.2743584,9 16.0333242,9 C15.7916129,9 15.5505787,9.09358547 15.3630324,9.27934912 Z"
        id="Back" fill="#FFFFFF"
        transform="translate(13.000000, 16.000000) scale(-1, 1) translate(-13.000000, -16.000000) "></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SimpleClose',
};
</script>

<style scoped>

</style>
