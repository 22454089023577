<template>
  <div class="fsw">
    <div id="webcam" style="position:relative; display: none;">
      <div class="countdown"></div>
      <video id="player" class="webcamVideo" autoplay playsinline width="100%"></video>
      <canvas style="display: none" id="canvas" width="455" height="607"></canvas>
    </div>
    <div v-show="!video">
      <div class="Videos">
        <div v-for="video in data.videos" class="VideoCell" :key="video.video_id">
          <div
            class="VideoCell__cover"
            :style="{ backgroundImage: `url(${video.cover.replace('{$width}', '576')})`}"
            @click="handleVideo(video)"
          >
            <playBtn />
          </div>
          <div class="VideoCell__title">
            {{ video.title }}
          </div>
        </div>
      </div>
      <div class="Videos">
        <div v-for="link in data.external_links" class="VideoCell" :key="link.title">
          <a
            class="VideoCell__cover"
            :style="{ backgroundImage: `url(${link.cover.replace('{$width}', '576')})`}"
            :href="link.url"
            :target="link.url.includes('livewall.tok.tv') ? '_self' : '_blank'"
          >
          </a>
          <div class="VideoCell__title">
            {{ link.title }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="video">
      <Player360
        v-if="showVideo360"
        :url="video.stream_hls"
        :id="video.video_id"
        :token="token"
        @exit="exit()"
        :options="data.videos"
        :showOptions="( showVideo360 || showVideo ) && rbv" @handleVideo="handleVideo"
        :type="get(video, 'video_type')"
      />
      <Player
        v-if="showVideo"
        :url="video.stream_hls"
        :id="video.video_id" :token="token"
        @exit="exit()"
        :options="data.videos"
        :showOptions="( showVideo360 || showVideo ) && rbv" @handleVideo="handleVideo"
        :type="get(video, 'video_type')"
      />
    </template>
    <transition
      enterActiveClass="animate__animated animate__fadeIn"
      leaveActiveClass="animate__animated animate__fadeOut"
    >
      <reaction-stripe
        v-show="( showVideo360 || showVideo ) && rbv || writing"
        :player="rdsPlayer"
        :player-instance="rdsPlayer"
        :player-state="true"
        :customFS="true"
        :isVisible="( showVideo360 || showVideo ) && rbv || writing"
        @activity="writingDelay()"
      />
    </transition>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import { filter, get, isFunction } from 'lodash';
import Player from '@/components/Player.vue';
import ReactionStripe from '@/components/ReactionStripeRDS.vue';
import Player360 from '@/components/Player360.vue';
import PlayBtn from './glyphs/PlayBtn.vue';

export default {
  name: 'RDS',
  components: {
    Player,
    Player360,
    PlayBtn,
    ReactionStripe,
  },
  data() {
    return {
      filter,
      get,
      showVideo: false,
      showVideo360: false,
      token: false,
      events: false,
      exp: false,
      status: false,
      data: false,
      video: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
    };
  },
  beforeMount() {
    this.$store.commit('appReady', true);
    const {
      status,
      token,
      exp,
      events,
    } = get(this.$route, 'query');
    this.token = token;
    this.status = status;
    this.exp = exp;
    this.events = events.split('|');
  },
  mounted() {
    let params = '?';
    this.events.forEach((e) => {
      params += `&event=${e}`;
    });
    params = params.replace('?&', '?');
    this.papi.get(`/rds${params}`, {
      headers: {
        'x-tok-token': this.token,
        'Accept-Language': this.locale,
      },
    }).then((response) => {
      this.data = get(response, 'data');
      const env = 'special';
      const reactionStripeConfig = {
        ...this.reactionStripeConfig,
        ADS_URL: this.conf[env].ADS_URL,
        appid: this.conf[env].appid,
        service: this.conf[env].service,
        host: this.conf[env].host,
        BASE_GROUP_PHOTO_URL: this.conf[env].BASE_GROUP_PHOTO_URL,
        BASE_SHARE_URL: `${this.conf[env].BASE_SHARE_URL + this.conf[env].appid}/${this.$t('lang')}?id=`,
        TWITTER_TEXT: encodeURI(this.$t('TWITTER_TEXT')),
      };
      window.start(this.token, reactionStripeConfig);
      window.initBar();
    });
    this.interval = setInterval(() => {
      const isF = isFunction(this.rdsPlayer.userActive);
      if (isF) {
        this.rbv = this.rdsPlayer.userActive();
      }
    }, 250);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState([
      'api',
      'papi',
      'locale',
      'rdsPlayer',
      'rdsPlayer360',
      'rdsPlayerShow',
      'rdsPlayer360Show',
      'reactionStripeConfig',
    ]),
    ...mapGetters(['getPI']),
  },
  methods: {
    ...mapMutations([
      'setRdsPlayer',
      'setRdsPlayer360',
      'setRdsPlayerShow',
      'setRdsPlayer360Show',
    ]),
    ...mapActions(['handleVideo']),
    writingDelay() {
      clearTimeout(this.writingTo);
      this.writing = true;
      this.writingTo = setTimeout(() => {
        this.writing = false;
      }, 5000);
    },
    exit() {
      this.video = false;
      this.showVideo = false;
      this.showVideo360 = false;
    },
    handleVideo(payload) {
      this.papi.get(`/video/${payload.video_id}`, {
        headers: {
          'x-tok-token': this.token,
          'Accept-Language': this.locale,
        },
      }).then((response) => {
        this.video = response.data;
        if (this.video.video_360) {
          this.showVideo360 = true;
          this.showVideo = false;
        } else {
          this.showVideo360 = false;
          this.showVideo = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.video-js-vr {
  height: 100vh;
}

.Videos {
  display: flex;
  padding: 20px;
}

.VideoCell {
  margin: 20px;
  flex: 0 1 33%;

  &__cover {
    padding-top: 56.25%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
  }

  &__title {
    margin-top: 15px;
    color: white;
  }
}

.playBtn {
  position: absolute;
  top: 0;
}

.videoContainer {
  position: relative;
}

.close {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 5;
  top: 20px;
  left: 20px;
}

.Options {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 10px;
  z-index: 505;
  background-color: rgba(0, 0, 0, 0.5);

  &__option {
    cursor: pointer;
    margin: 10px;
    flex: 0 1 100px;

    &__cover {
      padding-top: 56.25%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center center;
      background-size: cover;
    }

    &__title {
      font-size: 10px;
      margin-top: 15px;
      color: white;
    }
  }
}
</style>
