<template>
  <div v-show="showVideo" class="videoContainer" v-touch:tap="onTap">
    <div @click="exit()" class="close" id="closeButton">
      <simpleClose />
    </div>
    <transition
      enterActiveClass="animate__animated animate__fadeIn"
      leaveActiveClass="animate__animated animate__fadeOut"
    >
      <div class="Options" v-show="showOptions" id="optionsStripe">
        <div class="Options__option" v-for="option in fOptions" :key="option.video_id">
          <div
            class="Options__option__cover"
            :style="{ backgroundImage: `url(${option.cover.replace('{$width}', '324')})`}"
            @click="switchVideo(option)"
          >
          </div>
          <div class="Options__option__title">
            {{ option.title }}
          </div>
        </div>
      </div>
    </transition>
    <video
      id="RDS-SimplePlayer"
      class="video-js video-js-vr vjs-default-skin"
      controls=""
      playsinline=""
      width="640"
      height="300"
    ></video>
  </div>
</template>
<script>

import simpleClose from '@/components/glyphs/SimpleClose.vue';
import {
  mapMutations,
  mapState,
} from 'vuex';
import { filter } from 'lodash';

export default {
  props: ['url', 'token', 'options', 'showOptions', 'id', 'type'],
  name: 'Player',
  components: {
    simpleClose,
  },
  data() {
    return {
      showVideo: false,
      player: false,
      rbv: true,
      interval: false,
    };
  },
  mounted() {
    this.play(this.url);
  },
  methods: {
    ...mapMutations([
      'setRdsPlayer',
    ]),
    onTap() {
      this.$emit('tapped');
    },
    switchVideo(payload) {
      this.exit();
      this.$emit('handleVideo', payload);
    },
    exit() {
      this.$fullscreen.exit();
      this.showVideo = false;
      this.player.pause();
      this.player.dispose();
      this.$emit('exit');
    },
    play(url) {
      // eslint-disable-next-line no-multi-assign
      this.player = window.videojs('RDS-SimplePlayer', {
        inactivityTimeout: 5000,
        controlBar: {
          fullscreenToggle: false,
          volumePanel: false,
        },
      });
      if (this.type === 'live') this.player.controls(false);
      this.player.src({
        type: 'application/x-mpegURL',
        src: url,
      });
      this.setRdsPlayer(this.player);
      this.player.play();
      this.showVideo = true;
    },
  },
  computed: {
    ...mapState([
      'rdsPlayer',
      'rdsPlayer360',
      'rdsPlayerShow',
      'rdsPlayer360Show',
    ]),
    fOptions() {
      const filtered = filter(this.options, o => o.video_id !== this.id);
      return filtered;
    },
  },
};
</script>

<style lang="scss" scoped>
.videoContainer {
  position: relative;
}

.close {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 5;
  top: 20px;
  left: 20px;
}
</style>
