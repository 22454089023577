<template>
  <section>
    <navBar title="Paywall"></navBar>
    <div class="Faqs">
      <div class="Faqs__qa">
        <h1 class="Faqs__qa__title">{{ $t('faqTitle') }}</h1>
        <div class="Faqs__qa__item">
          <div class="Faqs__qa__item__q">
            <span class="head">{{ $t('Q') }}</span>
            <div class="body">{{ $t('d1') }}</div>
          </div>
          <div class="Faqs__qa__item__a">
            <span class="head">{{ $t('A') }}</span>
            <div class="body" v-html="$t('r1')"></div>
          </div>
        </div>
        <div class="Faqs__qa__item">
          <div class="Faqs__qa__item__q">
            <span class="head">{{ $t('Q') }}</span>
            <div class="body">{{ $t('d2') }}</div>
          </div>
          <div class="Faqs__qa__item__a">
            <span class="head">{{ $t('A') }}</span>
            <div class="body" v-html="$t('r2')"></div>
          </div>
        </div>
        <div class="Faqs__qa__item">
          <div class="Faqs__qa__item__q">
            <span class="head">{{ $t('Q') }}</span>
            <div class="body">{{ $t('d3') }}</div>
          </div>
          <div class="Faqs__qa__item__a">
            <span class="head">{{ $t('A') }}</span>
            <div class="body" v-html="$t('r3')"></div>
          </div>
        </div>
        <div class="Faqs__qa__item">
          <div class="Faqs__qa__item__q">
            <span class="head">{{ $t('Q') }}</span>
            <div class="body">{{ $t('d4') }}</div>
          </div>
          <div class="Faqs__qa__item__a">
            <span class="head">{{ $t('A') }}</span>
            <div class="body" v-html="$t('r4')"></div>
          </div>
        </div>
        <div class="Faqs__qa__item">
          <div class="Faqs__qa__item__q">
            <span class="head">{{ $t('Q') }}</span>
            <div class="body">{{ $t('d5') }}</div>
          </div>
          <div class="Faqs__qa__item__a">
            <span class="head">{{ $t('A') }}</span>
            <div class="body" v-html="$t('r5')"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
import get from 'lodash.get';
import navBar from './NavBar.vue';

export default {
  name: 'faqs',
  components: {
    navBar,
  },
  methods: {
    ...mapMutations([
      'appReady',
    ]),
  },
  beforeMount() {
    const alt = get(this.$route, 'query.alt', false);
    if (alt) {
      this.$router.push({ name: alt });
    }
  },
  mounted() {
    this.appReady(true);
  },
};
</script>

<style lang="scss" scoped>
.Faqs {
  color: white;
  display: flex;
  justify-content: center;

  &__qa {
    max-width: 800px;
    padding: 0 20px;

    &__title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 20px;
    }

    &__item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 20px 0;

      &:last-child {
        border-bottom-width: 0;
      }

      &__q {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 10px;
        display: flex;

        .head {
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 30px;
          background-color: white;
          font-size: 20px;
          color: black;
          line-height: 30px;
          text-align: center;
          margin-right: 15px;
          flex: 0 0 30px;
        }

        .body {
          padding-top: 5px;

          a {
            color: #F0DC00 !important;
          }
        }
      }

      &__a {
        font-size: 16px;
        line-height: 20px;
        display: flex;

        .head {
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 30px;
          background-color: white;
          font-size: 20px;
          color: black;
          line-height: 30px;
          text-align: center;
          margin-right: 15px;
          flex: 0 0 30px;
        }

        .body {
          padding-top: 5px;

          a {
            color: #F0DC00 !important;
          }
        }
      }
    }
  }
}
</style>
