<template>
  <div v-show="showVideo" class="videoContainer" v-touch:swipe="onTap">
    <div v-if="!noClose" @click="exit()" class="close">
      <simpleClose />
    </div>
    <transition
      enterActiveClass="animate__animated animate__fadeIn"
      leaveActiveClass="animate__animated animate__fadeOut"
    >
      <div class="Options" v-show="showOptions">
        <div class="Options__option" v-for="option in fOptions" :key="option.video_id">
          <div
            class="Options__option__cover"
            :style="{ backgroundImage: `url(${option.cover.replace('{$width}', '324')})`}"
            @click="switchVideo(option)"
          >
          </div>
          <div class="Options__option__title">
            {{ option.title }}
          </div>
        </div>
      </div>
    </transition>
    <div id="my-player" :class="noFullBtn ? 'noFull' : ''"></div>
  </div>
</template>

<script>

import simpleClose from '@/components/glyphs/SimpleClose.vue';
import {
  mapMutations,
  mapState,
} from 'vuex';
import { filter, omit } from 'lodash';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player-ui';

require('bitmovin-player-ui/dist/css/bitmovinplayer-ui.min.css');

export default {
  props: ['url', 'cover', 'token', 'options', 'showOptions', 'id', 'type', 'leftFix', 'plain', 'noClose', 'autoplay', 'reloadOnLock', 'noFullBtn'],
  name: 'Player360',
  components: {
    simpleClose,
  },
  data() {
    return {
      container: false,
      player: false,
      config: {
        // key: '7b0f1de9-1357-4596-ace5-84f2a7d05872',
        key: 'b3cdbd3e-4dc0-4809-a38d-36f78f7d4b40',
        playback: {
          autoplay: this.autoplay,
        },
      },
      showVideo: false,
      player360: false,
      rbv: true,
      interval: false,
      checkExist: false,
    };
  },
  mounted() {
    this.createPlayer();
  },
  methods: {
    ...mapMutations([
      'setRdsPlayer',
      'appReady',
    ]),
    createPlayer() {
      const self = this;
      this.appReady(true);
      this.container = document.getElementById('my-player');
      this.player = new Player(this.container, this.config);
      // this.player.setLogLevel('debug');
      this.setRdsPlayer(this.player);
      if (this.cover) {
        const poster = this.cover.replace('{$width}', '1080');
        this.player.setPosterImage(poster);
      }
      UIFactory.buildDefaultUI(this.player);

      const loadObj = {
        title: '',
        description: '',
        hls: this.url,
        vr: {
          contentType: 'single',
        },
      };
      this.player.on('play', () => {
        this.$emit('play');
      });
      this.player.on('playbackfinished', () => {
        this.$emit('finish');
      });
      if (this.reloadOnLock) {
        this.player.on('play', () => {
          this.$emit('play');
          setTimeout(() => {
            const isPlaying = self.player.isPlaying();
            if (!isPlaying) {
              this.player.load(this.plain ? omit(loadObj, 'vr') : loadObj);
            }
          }, 3500);
        });
      }

      this.player.load(this.plain ? omit(loadObj, 'vr') : loadObj).then(
        () => {
          this.showVideo = true;
          console.log('Successfully created Bitmovin Player instance');
        },
        (reason) => {
          console.log(`Error while creating Bitmovin Player instance: ${reason}`);
        },
      );
    },
    onTap() {
      this.$emit('tapped');
    },
    switchVideo(payload) {
      this.exit();
      this.$emit('handleVideo', payload);
    },
    exit() {
      this.$fullscreen.exit();
      this.showVideo = false;
      this.player.destroy();
      this.$emit('exit');
    },
  },
  computed: {
    ...mapState([
      'reactionStripeConfig',
      'rdsPlayer',
      'rdsPlayer360',
      'rdsPlayerShow',
      'rdsPlayer360Show',
      'conf',
    ]),
    fOptions() {
      const filtered = filter(this.options, o => o.video_id !== this.id);
      return filtered;
    },
  },
};
</script>

<style lang="scss">
  .noFull {
    .bmpui-ui-fullscreentogglebutton{
      display:none;
    }
  }
  .videoContainer {
    position: relative;
  }
  .close {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 5;
    top: 20px;
    left: 20px;
  }
  .video-js > canvas {
    left: 0;
  }
  .bmpui-ui-watermark {
    display: none;
  }
</style>
